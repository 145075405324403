import React from 'react';

const RightArrow: React.FC = () => {
  return (
    <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="74" height="74" rx="37" stroke="white"/>
      <path d="M35 31H44V40" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M31.2715 43.7279L43.9994 31" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default RightArrow;
