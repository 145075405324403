import { ITechnology } from 'src/utils/types';
import './TechnologiesSection.scss';

const Technology:React.FC<ITechnology> = ({ name, tools}) => {
  return (
    <div className="technology">
      <span className="technology__name">{name}</span>
      <div className="technology__tools">
        {tools.map((tool) => <span className="tool" key={tool}>{tool}</span>)}
      </div>
      
    </div>
  )
}

export default Technology;
