import React, { useState, useMemo } from 'react';
import { projects } from 'src/utils/constants';
import { MoreButton, Section } from 'src/components';
import { ISectionProps } from 'src/utils/types';
import './ProjectsSection.scss';

/**
 * Секция проектов компании.
 */

const ProjectsSection:React.FC<Pick<ISectionProps, 'refCallback'>> = ({ refCallback }) => {
  const [open, setOpen] = useState(false);

  const projectsListStyles = useMemo(() => {
    return open ? "projects__list" : ["projects__list","projects__list_hide"].join(' ')
  }, [open]);

  return (
    <Section 
      id="projects" 
      refCallback={refCallback}
      headerText={`Проекты, ${'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'} над которыми мы работали`}
      headerMessage='<< За годы работы нашими специалистами накоплен большой опыт разработки проектов в сферах финансов, безопасности, государственного управления и многих других >>'
    >
      <div className={projectsListStyles}>
        {projects.map((project) => {
          return (
            <div key={project.description} className="project">
              <div className="project__name" style={{ backgroundColor: project.backgroundColor, color: project.color }}>{project.name}</div>
              <p className="project__description">{project.description}</p>
            </div>
          )
        })}
      </div>
      <MoreButton open={open} setOpen={setOpen} />
    </Section>
  )
}

export default ProjectsSection;
