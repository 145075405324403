import { IVacancy } from '../types';

const vacancies: IVacancy[] = [
  {
    name: 'Middle тестировщик ПО (QAA)',
    experience: '1-3 года',
    salary: 'ЗП от 100 000 рублей',
    skills: ['Разработка автоматизированных тестов','Python','Selenium']
  },
  {
    name: 'Middle frontend разработчик',
    experience: '1-3 года',
    salary: 'ЗП от 100 000 рублей',
    skills: ['JavaScript','React','Шаблоны проектирования']
  },
  {
    name: 'Системный аналитик',
    experience: '1-3 года',
    salary: 'ЗП от 100 000 рублей',
    skills: ['Управление документацией','Управление проектами','REST API, JSON']
  },
  {
    name: 'Middle разработчик .net',
    experience: '2-3 года',
    salary: 'ЗП от 120 000 рублей',
    skills: ['.net','СУБД Microsoft SQL Server/PostgreSQL/MongoDB','Разработка web систем']
  }
]

export default vacancies;
