import { PropsWithChildren } from 'react';
import './Wrapper.scss';

const Wrapper: React.FC<PropsWithChildren> = ({ children }) => {
    return(
      <div className="wrapper">
        <div className="content">
          {children}
        </div> 
      </div>
    )
}

export default Wrapper;
