import React from 'react';
import { ISectionHeaderProps } from 'src/utils/types';
import './SectionHeader.scss';

const SectionHeader:React.FC<ISectionHeaderProps> = ({ headerText, headerMessage }) => {
  return (
    <div className="sectionHeader">
      <h2 className="sectionHeader__text">{headerText}</h2>
      <p className="sectionHeader__message">{headerMessage}</p>
    </div>
  )
}

export default SectionHeader;
