import { ITechnology } from '../types';

const technologies:ITechnology[] = [
  {
    name: 'Backend',
    tools: ['.NET Framework', '.NET Core', 'Node.js']
  },
  {
    name: 'СУБД',
    tools: ['MS SQL', 'PostgreSQL', 'MongoDB', 'Oracle PL/SQL', 'Entity Framework Core', 'ADO.NET', 'NHibernate', 'Dapper'],
  },
  {
    name: 'API и инструменты интеграции',
    tools: ['RESTful API', 'GraphQL', 'Swagger', 'Kafka', 'RabbitMQ',]
  },
  {
    name: 'Прочее',
    tools: ['Elasticsearch', 'Memcached', 'Redis', 'SharePoint 2010-2019', 'SharePoint Search']
  },
  {
    name: 'Frontend',
    tools: ['React', 'Redux', 'MobX', 'Angular', 'Material UI', 'JSS', 'TypeScript', 'KendoReact', 'Vite', 'Webpack', 'Ant Design', 'Vue.js', 'Sass/Less']
  },
  {
    name: 'Автоматизированное тестирование',
    tools: ['Selenium WebDriver', 'NUnit', 'xUnit.net']
  },
  {
    name: 'DevOps',
    tools: ['Astra Linux', 'Docker', 'AWS', 'Kubernetes', 'GitLab Jenkins', 'Grafana']
  },
]

export default technologies;
