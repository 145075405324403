import React from 'react';
import { IVacancy } from 'src/utils/types';
import './VacanciesSection.scss';

const Vacancy:React.FC<IVacancy> = ({ name, salary, experience, skills }) => {
  return (
    <div className="vacancy">
      <span className="vacancy__name">{name}</span>
      <div className="vacancy__info">
        <span>Опыт работы: {experience}</span>
        <span>Гибридный график</span>
        <span>{salary}</span>
      </div>
      <div className="vacancy__skills">
        {skills.map((skill) => <span key={skill}>{skill}</span>)}
      </div>
    </div>
  )
}

export default Vacancy;