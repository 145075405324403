import React from 'react';

const QPDIcon: React.FC = () => {
  return (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M41.4362 0.773848V7.86504C14.8665 1.01939 -3.93424 32.8471 13.7887 52.9258V62.2892C-15.2354 40.4883 5.18346 -6.59099 41.4362 0.773848ZM55.26 6.99404C84.2841 28.7949 63.8655 75.8738 27.6125 68.5086V61.4163C54.1839 68.2637 72.9823 36.4355 55.26 16.3568V6.99404Z" fill="#00726A"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.2459 34.5016C17.3215 25.0231 25.0275 17.3624 34.5238 17.3624C38.4139 17.3624 42.0037 18.6481 44.8916 20.8175C44.8916 14.4337 44.8916 8.04987 44.8916 1.66602C47.3221 2.42961 49.6372 3.45396 51.8035 4.70727C51.8035 14.7241 51.8026 24.7397 51.802 34.7573C51.7399 44.2475 44.0286 51.9217 34.5238 51.9217C30.6337 51.9217 27.044 50.6359 24.156 48.4666C24.156 54.8504 24.156 61.2342 24.156 67.618C21.7254 66.8546 19.4105 65.83 17.2441 64.5769C17.2441 54.5515 17.2452 44.5274 17.2459 34.5015V34.5016ZM34.5238 24.2743C40.2497 24.2743 44.8916 28.9161 44.8916 34.6421C44.8916 40.368 40.2497 45.0098 34.5238 45.0098C28.7979 45.0098 24.156 40.368 24.156 34.6421C24.156 28.9161 28.7979 24.2743 34.5238 24.2743Z" fill="#2E3333"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M52.7094 47.9688L65.018 60.2774L60.0942 65.1283L47.7305 52.8377L52.7094 47.9688Z" fill="#9D9E9E"/>
    </svg>
  )
}

export default QPDIcon;