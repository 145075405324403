import React from 'react';
import { VKIcon } from 'src/assets/svg';
import './Footer.scss';

const Footer:React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer__copyrights">
          <span className="copyrights__header">Все права защищены</span>
          <span className="copyrights__subHeader">
              Copyright © ООО "Квалитипоинт Девелопмент" {new Date().getFullYear()}
              <br/>
              ИНН 3664231709
          </span>
      </div>
      <div className="footer__contacts">
          <span>Мы в социальных сетях</span>
          <div className="contact__icons">
            <a href="https://vk.com/qpdev" target="_blank" rel="noreferrer">
              <VKIcon/>
            </a>
          </div>
      </div>
    </footer>
  )
}
export default Footer;