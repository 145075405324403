import React, { useMemo } from 'react';
import { MoreIcon } from 'src/assets/svg';
import { IMoreButtonProps } from 'src/utils/types';
import './MoreButton.scss';

const MoreButton:React.FC<IMoreButtonProps> = ({ open, setOpen }) => {

  const buttonStyle = useMemo(() => {
    return open ? "moreButton collapse" : "moreButton more" 
  }, [open]);

  return (
    <div className={buttonStyle}>
      <div className="moreButton__content" onClick={() => setOpen(!open)}>
        {open ? <span>Свернуть</span> : <span>Еще</span>}
        <MoreIcon />
      </div>
    </div>
  )
}

export default MoreButton;