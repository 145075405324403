import React, { useState, useMemo } from 'react';
import { MoreButton, Section } from 'src/components';
import { technologies } from 'src/utils/constants';
import Technology from './Technology';
import { ISectionProps } from 'src/utils/types';
import './TechnologiesSection.scss';

/**
 * Секция используемых технологий.
 */

const TechnologiesSection:React.FC<Pick<ISectionProps, 'refCallback'>> = ({ refCallback }) => {
  const [open, setOpen] = useState(false);

  const technologiesListStyles = useMemo(() => {
    return open ? "technologies__list" : ["technologies__list","technologies__list_hide"].join(' ');
  }, [open]);

  return (
    <Section 
      id="technologies" 
      refCallback={refCallback}
      headerText='Наши технологии и инструменты'
      headerMessage='<< Наше основное направление - это разработка корпоративных систем на стеке .net и react >>'
    >
      <div className={technologiesListStyles}>
        {technologies.map((technology) => <Technology key={technology.name} name={technology.name} tools={technology.tools} />)}
      </div>
      <MoreButton open={open} setOpen={setOpen} />
    </Section>
  )
}

export default TechnologiesSection;