import { INavigationItem } from '../types';

const navigation: INavigationItem[]  = [
  {
    name: 'О компании',
    anchor: 'about'
  },
  {
    name: 'Услуги',
    anchor: 'services'
  },
  {
    name: 'Технологии',
    anchor: 'technologies'
  },
  {
    name: 'Проекты',
    anchor: 'projects'
  },
  {
    name: 'Контакты',
    anchor: 'contacts'
  },
]

export default navigation;