import React from 'react';
import { RightArrow } from 'src/assets/svg';
import human1 from 'src/assets/img/human1.png';
import human2 from 'src/assets/img/human2.png';
import human3 from 'src/assets/img/human3.png';
import human4 from 'src/assets/img/human4.png';
import human5 from 'src/assets/img/human5.png';
import { ISectionProps } from 'src/utils/types';
import { Section } from 'src/components';
import MobileRightArrow from 'src/assets/svg/MobileRightArrow';
import './MainSection.scss';

/**
 * Главная секция.
 */
const MainSection:React.FC<Pick<ISectionProps, 'refCallback'>> = ({ refCallback }) => {

  return (
    <Section id="main" refCallback={refCallback}>
      <div className="main__leftBlock">    
        <div className="main__uniqueApplications">
          <svg className="main__svg">
            <clipPath id="main-clip-path" clipPathUnits="objectBoundingBox" >
              <path d="M0.524,0 H0.045 C0.02,0,0,0.025,0,0.056 V0.463 V0.944 C0,0.975,0.02,1,0.045,1 H0.479 C0.504,1,0.524,0.975,0.524,0.944 V0.519 C0.524,0.488,0.544,0.463,0.569,0.463 H0.955 C0.98,0.463,1,0.438,1,0.407 V0.056 C1,0.025,0.98,0,0.955,0 H0.524"></path></clipPath>
          </svg>
          <h2>Создаем уникальные приложения и решения для бизнеса <span>|</span></h2>
        </div>
        <div className="main__projects">
          <p className="projects__header">Более 60 завершенных <br/>проектов</p>
          <a href="#projects" className="projects__arrowButton">
            <RightArrow />
          </a>
          <a href="#projects" className="projects__mobileArrowButton">
            <MobileRightArrow />
          </a>
        </div>
      </div>
      <div className="main__rightBlock">
          
          <div className="main__team">
            <div className="team__humans">
              <div className="human1">
                <img src={human1} alt="сотрудник"/>
              </div>
              <div className="human2">
                <img src={human2} alt="сотрудник" />
              </div>
              <div className="human3">
                <img src={human3} alt="сотрудник" />
              </div>
              <div className="human4">
                <img src={human4} alt="сотрудник" />
              </div>
              <div className="human5">
                <img src={human5} alt="сотрудник" />
              </div>
            </div>
            <p className="team__header">40+ специалистов в области разработки программного обеспечения</p>
            <a href="#vacancies" className="team__arrowButton">
              <RightArrow />
            </a>
            <a href="#vacancies" className="team__mobileArrowButton">
              <MobileRightArrow />
            </a>
          </div>
          <div className="main__office">
            <span className="office__firstMessage">
              а когда обед?
            </span>
            <span className="office__secondMessage">
              сначала деплой
            </span>
            <span className="office__answer">
              наоборот
              ...
            </span>
          </div>
          <div className="main__projects">
            <p className="projects__header">Более 60 завершенных <br/>проектов</p>
            <a href="#projects" className="projects__arrowButton">
              <RightArrow />
            </a>
          </div>
      </div>
    </Section>
  )
}

export default MainSection;